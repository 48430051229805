<template>
  <el-drawer
    :title="title"
    :visible.sync="drawer"
    :direction="direction"
    :before-close="handleClose"
    destroy-on-close
    custom-class="drawerClass"
  >
    <el-form ref="form" :model="entity" label-width="100px" :rules="rules">
      <el-form-item label="所属公司" prop="CompanyId">
        <CompanySearchSelect
          v-model="entity.CompanyId"
          @select="CompanySelect"
        />
      </el-form-item>
      <el-form-item label="承运商编码" prop="CarrierCode">
        <el-input
          v-model="entity.CarrierCode"
          :disabled="this.title == '编辑'"
          placeholder="请输入承运商编码"
        ></el-input>
      </el-form-item>
      <el-form-item label="承运商名称" prop="CarrierName">
        <el-input
          v-model="entity.CarrierName"
          placeholder="请输入承运商名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="CarrierPhone">
        <el-input
          v-model="entity.CarrierPhone"
          placeholder="请输入联系电话"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="省/直辖市">
        <el-select
          v-model="entity.cProvince"
          placeholder="请选择省/直辖市"
          @change="changeProvince"
        >
          <el-option
            v-for="item in ProvinceList"
            :key="item.Id"
            :value="item.Id"
            :label="item.Name"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="市">
        <el-select
          v-model="entity.cCity"
          placeholder="请选择市"
          @change="changeCity"
        >
          <el-option
            v-for="item in CityList"
            :key="item.Id"
            :value="item.Id"
            :label="item.Name"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="区">
        <el-select v-model="entity.cCounty" placeholder="请选择区">
          <el-option
            v-for="item in CountyList"
            :key="item.Id"
            :value="item.Id"
            :label="item.Name"
          >
          </el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="地址" prop="CarrierAddress">
        <el-input
          v-model="entity.CarrierAddress"
          type="textarea"
          :autosize="{ minRows: 4 }"
        ></el-input>
      </el-form-item>
    </el-form>
    <div style="height: 50px"></div>
    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '5px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 99999,
      }"
    >
      <el-button @click="drawer = false">取消</el-button>
      <el-button type="primary" :loading="btnLoading" @click="handleSubmit"
        >保存</el-button
      >
    </div>
  </el-drawer>
</template>

<script>
import CompanySearchSelect from "@/components/CompanySearchSelect.vue";
export default {
  components: {
    CompanySearchSelect,
  },
  props: {
    parentObj: { type: Object },
  },
  data() {
    return {
      drawer: false,
      direction: "rtl",
      entity: {},
      rules: {
        CompanyId: [
          { required: true, message: "请选择所属公司", trigger: "change" },
        ],
        CarrierCode: [
          { required: true, message: "请输入承运商名称", trigger: "blur" },
        ],
        CarrierName: [
          { required: true, message: "请输入承运商编号", trigger: "blur" },
        ],
        cProvince: [
          { required: true, message: "请选择省/直辖市", trigger: "change" },
        ],
        cCity: [{ required: true, message: "请选择市", trigger: "change" }],
      },
      btnLoading: false,
      title: "",
      ProvinceList: [],
      CityList: [],
      CountyList: [],
    };
  },
  methods: {
    // 公司选择
    CompanySelect(row) {
      console.log(row);
    },
    init() {
      this.entity = {};
      this.drawer = true;
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    openForm(Id, CompanyId) {
      this.init();
      if (CompanyId) {
        this.entity.CompanyId = CompanyId;
      }
      this.title = "新增承运商";
      if (Id) {
        this.title = "编辑承运商";
        this.getTheData(Id);
      }
      this.getProvinceList();
    },
    handleClose(done) {
      done();
    },
    getTheData(id) {
      this.$http.post("/Base/Base_Carrier/GetTheData", { id }).then((res) => {
        if (res.Success) {
          this.entity = res.Data;
          // if (this.entity.cProvince) this.getCityList(this.entity.cProvince);
          // if (this.entity.cCity) this.getCountyList(this.entity.cCity);
        } else {
          this.$message.error(res.Msg);
        }
      });
    },
    // changeProvince(e) {
    //   this.$set(this.entity, "cCity", "");
    //   this.$set(this.entity, "cCounty", "");
    //   this.getCityList(e);
    // },
    // changeCity(e) {
    //   this.$set(this.entity, "cCounty", "");
    //   this.getCountyList(e);
    // },
    // // 省列表
    // getProvinceList() {
    //   this.$http
    //     .post("/Base_BasicData/Base_District/GetDataListByPId?pId=0", {})
    //     .then((resJson) => {
    //       this.ProvinceList = resJson.Data;
    //     });
    // },
    // // 市列表
    // getCityList(id) {
    //   this.$http
    //     .post("/Base_BasicData/Base_District/GetDataListByPId?pId=" + id, {})
    //     .then((resJson) => {
    //       this.CityList = resJson.Data;
    //     });
    // },
    // // 区列表
    // getCountyList(id) {
    //   this.$http
    //     .post("/Base_BasicData/Base_District/GetDataListByPId?pId=" + id, {})
    //     .then((resJson) => {
    //       this.CountyList = resJson.Data;
    //     });
    // },
    handleSubmit() {
      this.$refs["form"].validate((valid) => {
        if (!valid) {
          return;
        }
        this.btnLoading = true;
        this.$http
          .post("/Base/Base_Carrier/SaveData", this.entity)
          .then((res) => {
            this.btnLoading = false;
            if (res.Success) {
              this.$message.success("操作成功");
              this.parentObj.getDataList();
              this.drawer = false;
            } else {
              this.$message.error(res.Msg);
            }
          });
      });
    },
  },
};
</script>

<style>
.drawerClass {
  padding: 10px;
}
</style>
